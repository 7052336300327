<template>
  <v-container :fluid="true">
    <v-card v-if="success_loading">
      <v-card-text class="d-flex justify-content-between">
        <div>
          <div v-if="details.email">
            <span class="caption mr-1">Email:</span>
            <b>{{ details.email.address }}</b>
          </div>
          <div>
            <span class="caption mr-1">Name:</span>
            <b>{{ details.first_name }} {{ details.last_name }}</b>
          </div>
          <div>
            <span class="caption mr-1">Type/GEO:</span>
            <b>
              {{ details.phone.phone_type ? details.phone.phone_type : "-" }} /
              {{
                details.phone.phone_geo ? `${details.phone.phone_geo}` : "-"
              }}</b
            >
          </div>
          <div>
            <span class="caption mr-1">Carrier/Number:</span>
            <b>{{ details.phone.carrier ? details.phone.carrier : "-" }}</b> /
            <b v-if="details.phone && details.phone.number">
              {{ details.phone.number }}
              <v-icon v-if="details.phone.is_valid" color="green" small
                >mdi-check
              </v-icon>

              <v-icon
                v-else
                :color="details.phone.is_valid === false ? 'red' : 'orange'"
                small
                >mdi-close
              </v-icon>
              <v-icon
                v-if="
                  details.phone.has_watsapp === true ||
                  details.phone.has_watsapp === false
                "
                :color="
                  details.phone.has_watsapp === false ? 'gray' : '#008000'
                "
                small
                >mdi-whatsapp</v-icon
              >
            </b>
            <b v-else>-</b>
          </div>
        </div>
        <div>
          <div v-if="details.revenue">
            <span class="caption mr-1">Revenue:</span>
            <b>${{ details.revenue }}</b>
          </div>
          <div v-if="details.total_leads">
            <span class="caption mr-1">Total leads:</span>
            <b>${{ details.total_leads }}</b>
          </div>
        </div>
        <div>
          <div>Created at {{ renderDate(details.created_at) }}</div>
          <div>Updated at {{ renderDate(details.updated_at) }}</div>
        </div>
      </v-card-text>

      <v-card-text v-if="details.sources">
        <h6>Sources</h6>
        <v-data-table
          dense
          :headers="sourcesHeaders"
          :items="details.sources"
          hide-default-footer
          :itemsPerPage="+details.sources.length"
          disable-sort
        >
          <template v-slot:header.name="{ header }">
            {{ header.text.toUpperCase() }}
          </template>

          <template
            v-for="header in sourcesHeaders.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>

          <template v-slot:item.referer="{ item }">
            <a :href="item.referer" target="_blank">{{ item.referer }}</a>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider v-if="details.addresses" class="mx-4"></v-divider>
      <v-card-text v-if="details.addresses">
        <h6>Addresses</h6>
        <v-data-table
          dense
          :headers="addressesHeaders"
          :items="details.addresses"
          hide-default-footer
          :itemsPerPage="+details.addresses.length"
          disable-sort
        >
          <template v-slot:header.name="{ header }">
            {{ header.text.toUpperCase() }}
          </template>

          <template
            v-for="header in addressesHeaders.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider v-if="details.broadcasts" class="mx-4"></v-divider>
      <v-card-text v-if="details.broadcasts">
        <h6>Broadcasts</h6>
        <v-data-table
          dense
          :headers="broadcastsHeaders"
          :items="details.broadcasts"
          hide-default-footer
          :itemsPerPage="+details.broadcasts.length"
          disable-sort
        >
          <template v-slot:header.name="{ header }">
            {{ header.text.toUpperCase() }}
          </template>

          <template
            v-for="header in broadcastsHeaders.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  LOAD_DETAILS,
  SET_DEFAULT_STATE,
} from "../../store/modules/contacts/types";
import {
  requestStatus,
  renderDate,
  renderDateTime,
  renderDateShort,
} from "@/services/services";

export default {
  name: "Contact",
  data: () => ({
    success_loading: false,
    sourcesHeaders: [
      { text: "Network", value: "network" },
      { text: "Country", value: "country" },
      {
        text: "Offer",
        value: "offer",
        formatter: (x) => (x ? x.name || x.key : null),
      },
      { text: "Client ip", value: "client_ip" },
      { text: "Visit id", value: "visit_id" },
      { text: "Leads", value: "leads" },
      { text: "Referer", value: "referer" },
      {
        text: "Date",
        value: "date",
        formatter: (x) => (x ? renderDateTime(x) : null),
      },
    ],
    addressesHeaders: [
      { text: "Country", value: "country" },
      { text: "City", value: "city" },
      { text: "Address", value: "address" },
      { text: "ZIP", value: "zip" },
    ],
    broadcastsHeaders: [
      { text: "Name", value: "name" },
      { text: "Country", value: "country" },
      {
        text: "Created at",
        value: "created_at",
        formatter: (x) => (x ? renderDateTime(x) : null),
      },
      { text: "Leads", value: "leads" },
      {
        text: "Revenue",
        value: "revenue",
        formatter: (x) => (x ? `$${x}` : null),
      },
    ],
  }),
  computed: {
    renderDate: () => renderDate,
    renderDateShort: () => renderDateShort,
    renderDateTime: () => renderDateTime,
    ...mapState({
      details: (state) => state.contacts.details || {},
      status: (state) => state.contacts.status,
    }),
  },
  methods: {
    ...mapActions({
      onLoadDetails: `contacts/${LOAD_DETAILS}`,
    }),
    ...mapMutations({
      onClearData: `contacts/${SET_DEFAULT_STATE}`,
    }),
  },
  watch: {
    status(newValue) {
      this.success_loading = newValue.load_details === requestStatus.success;
    },
  },
  created() {
    this.onLoadDetails(this.$route.params.id);
  },
  beforeRouteLeave(to, from, next) {
    this.onClearData();
    next();
  },
};
</script>

<style scoped></style>
